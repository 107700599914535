import { default as dashboardhDWSO9oURJMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/admin/dashboard.vue?macro=true";
import { default as loginblspzv4iP2Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/admin/login.vue?macro=true";
import { default as choose_45categoryr01VKlzURpMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/choose-category.vue?macro=true";
import { default as defaultOuvQla0XduMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/default.vue?macro=true";
import { default as indexFisvk0ULpSMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/index.vue?macro=true";
import { default as claim_45prizeMgrApliPCdMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/claim-prize.vue?macro=true";
import { default as indexd1JgiRglm1Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/index.vue?macro=true";
import { default as max_45playedXUHMkLSz6IMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/max-played.vue?macro=true";
import { default as no_45winbrVTOMFuojMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimed6hNVokjizPMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/prize-claimed.vue?macro=true";
import { default as winY7f06JxZLJMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/win.vue?macro=true";
import { default as wrong_45answersFHfRjfLdJMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correct1rC5tn5F5KMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/answer-correct.vue?macro=true";
import { default as becoVXehG4YmMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/be.vue?macro=true";
import { default as congratulationsVrcX82qISfMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/congratulations.vue?macro=true";
import { default as formed2JSoxTVCMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/form.vue?macro=true";
import { default as loginomze0Ai8BfMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/login.vue?macro=true";
import { default as questionKF2Nq90mJ4Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/question.vue?macro=true";
import { default as thanksavYuwFvdESMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/thanks.vue?macro=true";
import { default as wheelRvT8XsaAd9Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardhDWSO9oURJMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginblspzv4iP2Meta || {},
    alias: ["/login"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45categoryr01VKlzURpMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaultOuvQla0XduMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/default.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexFisvk0ULpSMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/index.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prizeMgrApliPCdMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: indexd1JgiRglm1Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedXUHMkLSz6IMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45winbrVTOMFuojMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimed6hNVokjizPMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winY7f06JxZLJMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answersFHfRjfLdJMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correct1rC5tn5F5KMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: becoVXehG4YmMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulationsVrcX82qISfMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: formed2JSoxTVCMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginomze0Ai8BfMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: questionKF2Nq90mJ4Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thanksavYuwFvdESMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheelRvT8XsaAd9Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]