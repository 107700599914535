import revive_payload_client_4sVQNw7RlN from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_adVF2uRka6 from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_2NWfl2gjOj from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt-sanctum-auth/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import apiAuth_SUC8ex3FYc from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/apiAuth.ts";
import apiEmployees_jHzNVrs0Si from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/apiEmployees.ts";
import apiGames_huVCe9rdXP from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/apiGames.ts";
import apiPrizes_pVzgMsVf3H from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/apiPrizes.ts";
import click_outside_D2NiLGp7dJ from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/click-outside.ts";
import toast_ysMjUcU7eJ from "/home/forge/staging.quiz.zuyderland.nl/releases/20241106144157/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_adVF2uRka6,
  plugin_2NWfl2gjOj,
  plugin_client_UYiXMU8ZyN,
  apiAuth_SUC8ex3FYc,
  apiEmployees_jHzNVrs0Si,
  apiGames_huVCe9rdXP,
  apiPrizes_pVzgMsVf3H,
  click_outside_D2NiLGp7dJ,
  toast_ysMjUcU7eJ
]